<template>
  <div>
    <a-row type="flex">
      <h2>Categories</h2>
      <div style="margin-left: auto">
        <a-button type="primary" @click="handleAddButtonClick">
          Add
        </a-button>
      </div>
    </a-row>

    <a-list item-layout="horizontal" :data-source="categories" rowKey="_id">
      <a-list-item slot="renderItem" slot-scope="item">
        <a-list-item-meta>
          <span slot="title">{{ item.name }}</span>
          <a-avatar slot="avatar" :src="item.thumbnailUrl" />
        </a-list-item-meta>
      </a-list-item>
    </a-list>

    <a-modal
      v-model="newCategoryModalVisible"
      title="Add a new category"
      @ok="handleAddCategory"
      :ok-button-props="{ props: { disabled: !newCategory.thumbnailUrl } }"
    >
      <div>
        <a-form layout="vertical">
          <a-form-item label="Name">
            <a-input v-model="newCategory.name" />
          </a-form-item>

          <a-form-item label="Upload">
            <a-upload
              :action="getUploadUrl"
              name="image"
              list-type="picture"
              @change="handleUploadStateChange"
            >
              <a-button> <a-icon type="upload" /> Click to upload </a-button>
            </a-upload>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: mapState({
    categories(state) {
      return state.video.categories
    },
  }),
  data() {
    return {
      newCategoryModalVisible: false,
      newCategory: {
        name: '',
        thumbnailUrl: null,
      },
    }
  },
  methods: {
    ...mapActions({
      getCategories: 'video/getCategories',
      createCategory: 'video/createCategory',
    }),
    handleAddButtonClick() {
      this.newCategoryModalVisible = true
    },
    handleAddCategory() {
      this.createCategory(this.newCategory).then(() => {
        this.$message.success('Category has been added!')
      })
      this.newCategory = {
        name: '',
        thumbnailUrl: null,
      }
      this.newCategoryModalVisible = false
    },
    getUploadUrl() {
      return `${process.env.VUE_APP_API_HOST}/upload`
    },
    handleUploadStateChange({ file }) {
      if (file.response?.url) {
        this.newCategory.thumbnailUrl = file.response.url
      }
    },
  },
  mounted() {
    this.getCategories()
  },
}
</script>
