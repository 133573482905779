<template>
  <div>
    <a-row type="flex">
      <h2>Videos</h2>
      <div style="margin-left: auto">
        <a-button type="primary" @click="handleAddButtonClick">
          Add
        </a-button>
      </div>
    </a-row>

    <a-list item-layout="horizontal" :data-source="videos">
      <a-list-item slot="renderItem" slot-scope="item">
        <a-list-item-meta :description="`${item.captionCount || 0} captions`">
          <a slot="title" :href="`/videos/${item._id}`">{{ item.title }}</a>
          <a-avatar slot="avatar" :src="item.thumbnails.default.url" />
        </a-list-item-meta>
      </a-list-item>
    </a-list>

    <a-modal
      v-model="newVideoModalVisible"
      title="Add a new video"
      @ok="handleAddVideo"
    >
      <div>
        <a-form layout="vertical">
          <a-form-item label="Youtube video ID">
            <a-input v-model="newVideoId" />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  computed: mapState({
    videos: state => Object.values(state.video.all),
  }),
  data() {
    return {
      newVideoId: '',
      newVideoModalVisible: false,
    }
  },
  methods: {
    ...mapActions({
      createVideo: 'video/createVideo',
    }),
    handleAddButtonClick() {
      this.newVideoModalVisible = true
    },
    handleAddVideo() {
      this.createVideo(this.newVideoId).then(() => {
        this.$message.success('Video has been added successfully!')
        this.newVideoId = ''
        this.newVideoModalVisible = false
      })
    },
  },
  created() {
    this.$store.dispatch('video/getVideos')
  },
}
</script>
